.plan-variants-row {
	display: flex;
	max-width: 375px;
	width: 100%;
	justify-content: space-between;
}

.plan-variants-badge {
	position: absolute;
	top: 0;
	transform: translateY(-55%);
	right: 4px;
	padding: 3px 8px;
	border-radius: 12px;
	background: #11bc59;
	color: #fff;
	font-family: Gordita-Medium;
	font-size: 10px;
}

.plan-variants-monthly-price {
	position: absolute;
	bottom: 0;
	left: 0;
	width: 100%;
	background: #fffaf3;
	border-radius: 0 0 12px 12px;
	padding: 8px 0;
	text-align: center;
}

.plan-variants-monthly-price p {
	margin-left: auto;
	margin-bottom: 0;
	display: inline;
	font-family: Gordita-Regular;
	color: #4462ef;
	font-size: 16px;
}

.plan-variants-row-selected {
	display: flex;
	align-items: start;
	width: 312px;
	flex-direction: column;
	justify-content: space-between;
	padding-bottom: 9px;
	padding-top: 9px;
	border-radius: 12px;
	background: #f4f3ff;
}

.plan-variants-circle {
	display: flex;
	width: 18px;
	height: 18px;
	padding: 6px 4px;
	align-items: flex-start;
	gap: 10px;
	border-radius: 9px;
	border: 1px solid #d6d6d6;
	background: #fff;
}

.plan-variants-circle-selected {
	display: flex;
	width: 18px;
	height: 18px;
	padding: 6px 4px;
	align-items: flex-start;
	gap: 10px;
	border-radius: 9px;
	border: 2px solid white;
	background: #534de0;
}

.plan-variants-name {
	color: #534de0;
	text-align: center;
	font-family: Poppins-Regular;
	width: 100%;
	font-size: 24px;
	align-self: center;
	font-style: normal;
	font-weight: 500;
	line-height: 32px; /* 133.333% */
}

.plan-variants-monthly-price {
	color: #1a1a1a;
	font-feature-settings: 'clig' off, 'liga' off;
	font-family: Poppins-Regular;
	align-self: center;
	font-size: 14px;
	font-style: normal;
	font-weight: 500;
	margin-top: 8px;
	line-height: normal;
}

.plan-variants-only-today {
	color: #1a1a1a;
	text-align: center;
	align-self: center;
	font-feature-settings: 'clig' off, 'liga' off;
	font-family: Poppins-Regular;
	font-size: 20px;
	text-align: start;
	margin-top: 10px;
	font-style: normal;
	font-weight: 600;
	line-height: normal;
}

.plan-variants-offer {
	color: var(--Black, #1b1625);
	font-family: Gordita-Medium;
	font-size: 14px;
	margin-left: 8px;
	font-style: normal;
	font-weight: 500;
	line-height: 20px; /* 142.857% */
}

/*.plan-variants-monthly-price span{*/
/*    color: #1A1A1A;*/
/*    font-feature-settings: 'clig' off, 'liga' off;*/
/*    font-family: DM Sans;*/
/*    font-size: 16px;*/
/*    margin-top: 16px;*/
/*    font-style: normal;*/
/*    font-weight: 400;*/
/*    line-height: normal;*/

/*}*/

.plan-variants-total-price {
	color: #1a1a1a;
	font-feature-settings: 'clig' off, 'liga' off;
	font-family: Poppins-Regular;
	align-self: center;
	font-size: 24px;
	margin-top: 6px;
	font-style: normal;
	font-weight: 700;
	line-height: 32px; /* 160% */
}

.plan-variants-strike-price {
	color: #a4a4a4;
	font-feature-settings: 'clig' off, 'liga' off;
	font-family: Poppins-Regular;
	font-size: 16px;
	margin-top: 6px;
	margin-left: 4px;
	align-self: center;
	font-style: normal;
	font-weight: 500;
	text-decoration: line-through;
	line-height: normal;
}

.price-container {
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: 100%;
	cursor: pointer;
}

.price-left-container {
	display: flex;
	align-items: center;
	margin-left: 4px;
}

.price-radio {
	display: flex;
	width: 16px;
	height: 16px;
	padding: 6px 4px;
	flex-direction: column;
	align-items: flex-start;
	gap: 10px;
	flex-shrink: 0;
	border-radius: 10px;
	border: 1px solid var(--Primary-Gradient, #4462ef);
	background: var(--White, #fff);
}
.price-radio-selected {
	display: flex;
	width: 16px;
	height: 16px;
	padding: 6px 4px;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	gap: 10px;
	flex-shrink: 0;
	border-radius: 50%; /* Make the border circular */
	border: 2px solid var(--Primary-Gradient, #fff); /* Adjust the border thickness */
	background: var(--White, #4462ef); /* Set background color to white */
	box-shadow: 0 0 0 1px var(--White, #4462ef); /* Create the white ring gap */
}

.price-title {
	color: var(--Black, #1b1625);
	font-family: Gordita-Bold;
	font-style: normal;
	font-weight: 700;
	margin-left: 10px;
	line-height: 32px; /* 133.333% */
}

.price-title span {
	color: var(--Body-Font, #4a4a4a);
	font-family: Gordita-Medium;
	font-size: 16px;
	font-style: normal;
	font-weight: 500;
	line-height: 24px; /* 150% */
}

.price-subtitle-selected {
	color: var(--Black, #1b1625);
	font-family: Gordita-Medium;
	font-size: 14px;
	font-style: normal;
	font-weight: 700;
	line-height: 24px; /* 150% */
}

.price-subtitle {
	color: var(--Black, #4a4a4a);
	font-family: Gordita-Medium;
	font-size: 14px;
	font-style: normal;
	font-weight: 500;
	line-height: 24px; /* 150% */
}
