.primary-button {
	padding: 10px 20px;
	border-radius: 12px;
	background: linear-gradient(106deg, #4462ef 32.65%, #1b3dd8 105.18%);
	color: white;
	border: none;
	cursor: pointer;
	display: flex;
	justify-content: center;
	align-items: center;
	gap: 6px;
	margin-right: 4px;
	overflow: hidden;
	flex: 1 0 0;
}

.primary-button:hover {
	opacity: 1;
}

.primary-button-title {
	color: #fff;
	text-align: center;
	font-feature-settings: 'dlig' on;
	font-family: Gordita-Medium;
	font-size: 12px;
	font-style: normal;
	font-weight: 500;
	line-height: 16px;
	position: relative;
}

.primary-button-title.disabled {
	color: #a4a4a4;
}

.primary-button-disabled {
	background: #e4e4e4;
	color: #a4a4a4;
	cursor: not-allowed;
}

.primary-button-shimmer {
	position: absolute;
	top: -10%;
	left: 0;
	width: 8px;
	height: 120%;
	transform: rotate(20deg);
	background-color: #f4f4f455;
	animation: shimmer 3s infinite;
}

@keyframes shimmer {
	0% {
		left: -5%;
	}
	50% {
		left: 100%;
	}
	100% {
		left: 100%;
	}
}
