.plan-screen {
	width: 100%;
	max-width: 420px;
	margin: auto;
	padding: 16px;
	position: relative;
	padding-bottom: 250px;
}

.plan-screen p {
	margin-bottom: 0;
}

.plan-screen-header {
	position: relative;
}

.plan-screen-header-bg {
	position: absolute;
	bottom: 0;
	left: -16px;
	width: calc(100% + 32px);
	height: 30%;
	z-index: -1;
	background-image: linear-gradient(to bottom, #ffffff, #e8ecff);
}

.plan-screen-title {
	color: #1b1625;
	text-align: center;
	font-family: Gordita-Medium;
	font-size: 24px;
	font-style: normal;
	font-weight: 500;
	line-height: 24px;
	margin-top: 24px;
}

.plan-screen-title span {
	color: #4462ef;
}

.plan-screen-header-cards {
	display: flex;
	flex-wrap: wrap;
	gap: 8px;
	margin-bottom: 36px;
	margin-top: 16px;
}

.plan-screen-header-cards img {
	width: calc(50% - 4px);
}

.plan-screen-header-numbers {
	display: flex;
	gap: 8px;
	margin-bottom: 8px;
}

.plan-screen-header-numbers-item {
	border-radius: 8px;
	background-color: #f4f4f480;
	padding: 8px;
	flex: 1;
	min-height: 100px;
	display: flex;
	flex-direction: column;
	justify-content: end;
}

.plan-screen-header-numbers-item-number {
	color: #4462ef;
	font-family: Gordita-Bold;
	font-size: 24px;
	font-style: normal;
	font-weight: 700;
	line-height: normal;
}

.plan-screen-header-numbers-item-number img {
	width: 22px;
	margin-bottom: 8px;
}

.plan-screen-header-numbers-item-text {
	color: #1b1625;
	font-family: Gordita-Regular;
	font-size: 12px;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
}

.plan-screen-header-numbers-item-icons {
	display: flex;
	margin-bottom: 8px;
}

.plan-screen-header-numbers-item-icons img {
	width: 18px;
	margin-right: 4px;
}

.plan-screen-header-testimonials {
	margin-top: 48px;
}

.plan-screen-header-testimonials-swiper {
	margin-top: 24px;
}

.plan-screen-header-testimonials-swiper-slide img {
	width: 100%;
}

.plan-screen-header-stats {
	position: relative;
	height: 150px;
	display: flex;
	justify-content: center;
	align-items: center;
	overflow: hidden;
}

.plan-screen-header-stats-item {
	text-align: center;
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
}

.plan-screen-header-stats-item:nth-child(1) {
	animation: topDownSlide1 12s infinite;
}

.plan-screen-header-stats-item:nth-child(2) {
	animation: topDownSlide2 12s infinite;
}

.plan-screen-header-stats-item:nth-child(3) {
	animation: topDownSlide3 12s infinite;
}

@keyframes topDownSlide1 {
	0% {
		top: -200%;
	}
	6% {
		top: 50%;
	}
	27% {
		top: 50%;
	}
	33% {
		top: 200%;
	}
	100% {
		top: 200%;
	}
}

@keyframes topDownSlide2 {
	0% {
		top: -200%;
	}
	33% {
		top: -200%;
	}
	39% {
		top: 50%;
	}
	60% {
		top: 50%;
	}
	66% {
		top: 200%;
	}
	100% {
		top: 200%;
	}
}

@keyframes topDownSlide3 {
	0% {
		top: -200%;
	}
	66% {
		top: -200%;
	}
	72% {
		top: 50%;
	}
	94% {
		top: 50%;
	}
	100% {
		top: 200%;
	}
}

.plan-screen-header-stats-item-number {
	color: #1b1625;
	font-family: Gordita-Bold;
	font-size: 20px;
	font-style: normal;
	font-weight: 700;
	line-height: 28px;
}

.plan-screen-header-stats-item-text {
	color: #1b1625;
	font-family: Gordita-Regular;
	font-size: 16px;
	font-style: normal;
	font-weight: 400;
	line-height: 24px;
}

.plan-screen-header-stats-item-text span {
	font-family: Gordita-Medium;
}

.plan-screen-about {
	margin-top: 40px;
}

.plan-screen-about-item {
	margin-top: 36px;
}

.plan-screen-about-item-video {
	aspect-ratio: 513/465;
	width: 100%;
	position: relative;
	border-radius: 12px;
}

.plan-screen-about-item video {
	width: 100%;
	position: absolute;
	transition: all 0.4s;
	border-radius: 12px;
}

.plan-screen-about-item-title {
	color: #1b1625;
	font-family: Gordita-Medium;
	font-size: 20px;
	font-style: normal;
	font-weight: 500;
	line-height: 28px;
	margin-top: 12px;
}

.plan-screen-about-item-list {
	position: relative;
	margin-top: 12px;
}

.plan-screen-about-item-list-left {
	position: absolute;
	width: 6px;
	border-radius: 50px;
	height: 100%;
	left: 0;
	top: 0;
	background-color: #e4e4e4;
}

.plan-screen-about-item-list-item {
	position: relative;
}

.plan-screen-about-item-list-item:not(:last-child) {
	margin-bottom: 12px;
}

.plan-screen-about-item-list-item-left {
	position: absolute;
	left: 0;
	width: 6px;
	height: 100%;
	border-radius: 50px;
	background-color: #4462ef;
}

.plan-screen-about-item-list-item p {
	margin-left: 16px;
	color: #1b1625;
	font-family: Gordita-Medium;
	font-size: 14px;
	font-style: normal;
	font-weight: 500;
	line-height: 20px;
}

.plan-screen-courses {
	margin-top: 36px;
}

.plan-screen-courses-swiper {
	margin-top: 16px;
}

.plan-screen-courses-swiper-slide img {
	width: 100%;
}

.plan-screen-designed {
	position: relative;
}

.plan-screen-designed-item {
	background-color: #f4f4f4;
	border-radius: 8px;
	padding: 12px;
	margin-bottom: 12px;
	position: sticky;
	border: 1px solid #e4e4e4;
}

.plan-screen-designed-item:nth-child(2) {
	top: 16px;
}
.plan-screen-designed-item:nth-child(3) {
	top: 32px;
}
.plan-screen-designed-item:nth-child(4) {
	top: 48px;
}
.plan-screen-designed-item:nth-child(5) {
	top: 64px;
}
.plan-screen-designed-item:nth-child(6) {
	top: 80px;
}

.plan-screen-designed-item img {
	width: 60px;
	margin-bottom: 12px;
}

.plan-screen-designed-item-title {
	color: #4462ef;
	font-family: Gordita-Medium;
	font-size: 16px;
	font-weight: 500;
	line-height: 24px;
}

.plan-screen-designed-item-desc {
	color: #4a4a4a;
	font-family: Gordita-Regular;
	font-size: 14px;
	font-weight: 400;
	line-height: 24px;
}

.plan-screen-cta {
	padding: 16px;
	border-radius: 8px;
	margin: 48px 0 36px;
	background-image: url(../../assets//webp-images/plan-cta-bg.png);
	background-size: cover;
	background-position: center;
}

.plan-screen-cta-title {
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 4px;
}

.plan-screen-cta-title img {
	width: 24px;
}

.plan-screen-cta-title p {
	color: #eb5757;
	text-align: center;
	font-family: Gordita-Medium;
	font-size: 16px;
	font-style: normal;
	font-weight: 500;
	line-height: 24px;
}

.plan-screen-cta-button {
	border-radius: 8px;
	width: 100%;
	margin-top: 8px;
	animation: shake 2s infinite;
}

@keyframes shake {
	0% {
		transform: translateX(0);
	}
	5% {
		transform: translateX(-3px);
	}
	10% {
		transform: translateX(3px);
	}
	15% {
		transform: translateX(-3px);
	}
	20% {
		transform: translateX(3px);
	}
	25% {
		transform: translateX(0);
	}
	100% {
		transform: translateX(0);
	}
}

.plan-screen-reviews-item {
	padding: 16px;
	border-radius: 8px;
	background-color: #f4f4f480;
}

.plan-screen-reviews-swiper {
	margin-top: 32px;
}

.plan-screen-reviews-item-stars {
	display: flex;
	gap: 4px;
}

.plan-screen-reviews-item-name {
	color: #4a4a4a;
	font-family: Gordita-Medium;
	font-size: 12px;
	font-style: normal;
	font-weight: 500;
	line-height: normal;
	margin-top: 8px;
}

.plan-screen-reviews-item-title {
	color: #1b1625;
	font-family: Gordita-Medium;
	font-size: 16px;
	font-style: normal;
	font-weight: 500;
	line-height: 24px;
	margin-top: 20px;
}

.plan-screen-reviews-item-desc {
	color: #4a4a4a;
	font-family: Gordita-Regular;
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	line-height: 20px;
}

.plan-screen-video {
	margin: 36px 0 48px;
	position: relative;
}

.plan-screen-video div {
	padding: 4px 8px;
	position: absolute;
	top: 8px;
	right: 8px;
	border-radius: 1000px;
	background-color: #ffffff55;
}

.plan-screen-video div img {
	margin-top: -4px;
	width: 16px;
	filter: invert(100%) sepia(0%) saturate(13%) hue-rotate(225deg)
		brightness(100%) contrast(103%);
}

.plan-screen-instructors-swiper {
	margin-top: 24px;
}

.plan-screen-instructors-swiper-slide img {
	width: 100%;
}

.plan-screen-users {
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 32px;
	background-color: #f8f8f8;
	width: calc(100% + 32px);
	margin-left: -16px;
	margin-top: 48px;
}

.plan-screen-users img {
	width: 100%;
	margin-left: -16px;
}

.plan-screen-faqs {
	margin-top: 36px;
}

.plan-screen-faqs p {
	margin-bottom: 16px;
}

.plan-screen-founders {
	margin-top: 48px;
	position: relative;
}

.plan-screen-founders-swiper {
	margin-top: 24px;
}

.plan-screen-founders-swiper-slide img {
	width: 100%;
}

.plan-screen-founders-bg {
	position: absolute;
	top: -50%;
	left: -16px;
	width: calc(100% + 32px);
	height: 100%;
	z-index: -1;
	background-image: linear-gradient(to bottom, #ffffff, #e8ecff, #ffffff);
}

.plan-screen-footer {
	position: fixed;
	bottom: 0;
	left: 50%;
	width: 100%;
	padding: 8px 16px 16px;
	max-width: 420px;
	z-index: 10000;
	overflow: hidden;
	background-color: white;
	transform: translateX(-50%);
	border-radius: 12px 12px 0 0;
	box-shadow: 1px 2px 12px 2px #00000022;
}

.plan-screen-footer-cancel {
	color: #a4a4a4;
	text-align: center;
	font-family: Gordita-Medium;
	font-size: 12px;
	font-style: normal;
	font-weight: 500;
	margin-top: 8px;
}

.plan-screen-login-modal .flag-dropdown {
	margin-top: -10px;
}
